<template>
  <div>
    <app-header />

    <div v-if="! is_loading">
      
      <div class="container py-5">
        <div class="alert alert-warning" v-if="has_error">
          <h3>Failed!</h3>
          <p>The verification token is invalid. Please try again.</p>
        </div>
        <div v-if="! has_error" class="text-center">
          <img src="/img/email-verified.png" alt="verified" height="300px">
          <h3>Your email has been verified</h3>
          <router-link :to="{ name: 'home' }" class="btn btn-primary mt-3"><i class="lni lni-home me-2"></i>Go Home</router-link>
        </div>
      </div>
    </div>

    <loading v-if="is_loading" />

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      is_loading: false,
      has_error: false
    }
  },

  methods: {
    fetch() {
      this.is_loading = true

      let data = {
        token: this.$route.params.token
      }

      this.$axios.post('/api/v1/verify-email', data).then(() => {
        this.has_error = false
        this.is_loading = false
        // window.location.href = this.$router.resolve({ name: 'my-account.home' }).href
      }).catch(() => {
        this.has_error = true
        this.is_loading = false
      })
    }
  }
}
</script>